
import { Options, Vue, prop } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  data = prop<string>({
    default: "",
    type: String
  });
  customMessage = prop<string>({
    default: "",
    type: String
  });
  customHeaderMessage = prop<string>({
    default: "",
    type: String
  });
  buttonAction = prop<boolean>({
    required: false,
    type: Boolean
  });
  icon = prop<string>({
    default: "noData_empty",
    type: String
  });
}

@Options({
  emits: ["action"],
  components: {}
})
export default class EmptyList extends Vue.with(Props) {
  get accountIForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  get messageEmptyError() {
    return this.accountIForeign ? this.data : `Belum ada ${this.data} yang dapat ditampilkan`
  }
}
