import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { PodDexData, ReasonData } from "@/domain/entities/PodDex";
import { PodDexPresenter } from "../presenters/PodDexPresenter";
import { container } from "tsyringe";
import {
  CheckPaymentStatusApiRequest,
  ReasonListDex,
  RequestListPodDex,
  UpdatePodDexApiRequest
} from "@/data/payload/api/PodDexRequest";
import { Entities } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  isUrlUpdateVersion,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";

export interface PodDexState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({ namespaced: true, dynamic: true, store, name: "incoming-pod-dex" })
class PodDexStore extends VuexModule implements PodDexState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public filter = {
    startDate: "",
    endDate: "",
    search: "",
    destinationCityCode: "",
    product: "",
    sttType: "" as string | OptionsClass,
    paymentMethod: "" as string | OptionsClass
  };
  public pagination = {
    page: 1,
    limit: 10
  };
  public reasonList = [] as ReasonData[];
  public podDexList = new Entities(false, 400, "", new Pagination(1, 10), []);
  public podDexDetailData = {};
  public isConfirm = false;
  public isErrorUpdate = false;
  public isLoadingUpdate = false;
  public status = "";
  public type = "";
  public isFirstRequest = false;
  public isPaid = false;
  public isLoadingDex = false;

  @Action
  getPodDexList(params: RequestListPodDex) {
    this.setIsLoading(true);
    const presenter = container.resolve(PodDexPresenter);
    return presenter
      .getPodDexList(params)
      .then((res: Entities) => {
        this.setPodDex(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public async fetchPodDexList() {
    this.setFirstRequest(false);
    await this.getPodDexList(
      new RequestListPodDex({
        page: this.podDexList.pagination.page,
        limit: this.podDexList.pagination.limit,
        search: this.filter.search,
        startDate: this.filter.startDate
          ? new Date(this.filter.startDate).toLocaleDateString("fr-CA")
          : "",
        endDate: this.filter.endDate
          ? new Date(this.filter.endDate).toLocaleDateString("fr-CA")
          : "",
        type: this.type,
        status: this.status,
        version: isUrlUpdateVersion("poddex"),
        typeCod: (this.filter.sttType as OptionsClass)?.value,
        paymentMethod: (this.filter.paymentMethod as OptionsClass)?.value
      })
    );
  }

  @Action
  public initDataTable() {
    this.setFirstRequest(true);
    this.setSearch("");
    this.setError(false);
    this.selectType("");
    this.resetTable();
  }

  @Action
  getReasonList(params: ReasonListDex) {
    this.setLoadingDex(true);
    const presenter = container.resolve(PodDexPresenter);
    return presenter
      .getListReason(params)
      .then((res: ReasonData[]) => {
        this.setReasonList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoadingDex(false));
  }

  @Action
  getPodDownload(params: ApiRequestList) {
    const presenter = container.resolve(PodDexPresenter);
    return presenter.getPodDownload(params);
  }

  @Action
  updatePodDex(params: UpdatePodDexApiRequest) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(PodDexPresenter);
    return presenter
      .updatePodDex(params)
      .then(() => {
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, params.isCustomTitle ? params.isCustomTitle : "Update POD/DEX Gagal !", () =>
            this.updatePodDex(params)
          )
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  checkPaymentStatus(params: { sttNo: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(PodDexPresenter);
    return presenter
      .checkPaymentStatus(new CheckPaymentStatusApiRequest(params.sttNo))
      .then(() => {
        this.setIsPaid(true);
      })
      .catch(() => {
        this.setIsPaid(false);
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public async selectStatus(value: string) {
    this.selectType(value);
    await this.getPodDexList(
      new RequestListPodDex({
        page: this.podDexList.pagination.page,
        limit: this.podDexList.pagination.limit,
        search: this.filter.search,
        startDate: this.filter.startDate
          ? new Date(this.filter.startDate).toLocaleDateString("fr-CA")
          : "",
        endDate: this.filter.endDate
          ? new Date(this.filter.endDate).toLocaleDateString("fr-CA")
          : "",
        type: this.type,
        status: this.status,
        version: isUrlUpdateVersion("poddex")
      })
    );
  }

  @Mutation
  public selectType(value: string) {
    this.status = value;
  }

  @Mutation
  public setLoadingDex(value: boolean) {
    this.isLoadingDex = value;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.isFirstRequest = value;
  }

  @Mutation
  public setType(value: string) {
    this.type = value;
  }

  @Mutation
  public setLoadingUpdate(val: boolean) {
    this.isLoadingUpdate = val;
  }

  @Mutation
  public setErrorUpdate(val: boolean) {
    this.isErrorUpdate = val;
  }

  @Mutation
  public setPodDexDetailData(data: PodDexData) {
    this.podDexDetailData = data;
  }

  @Mutation
  public onReset() {
    this.filter.search = "";
    this.filter.startDate = "";
    this.filter.endDate = "";
    this.filter.sttType = "";
    this.filter.paymentMethod = "";
  }

  @Mutation
  public setResetPodDexDetailData() {
    this.podDexDetailData = {};
  }

  @Mutation
  private setPodDex(data: Entities) {
    this.podDexList = data;
  }

  @Mutation
  public setReasonList(data: ReasonData[]) {
    this.reasonList = data;
  }

  @Mutation
  public setConfirm(value: boolean) {
    this.isConfirm = value;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.filter.search = value;
  }

  @Mutation
  public setStartDate(value: any) {
    this.filter.startDate = value;
  }

  @Mutation
  public setEndDate(value: any) {
    this.filter.endDate = value;
  }

  @Mutation
  public setDestinationCityCode(value: string) {
    this.filter.destinationCityCode = value;
  }

  @Mutation
  public setProduct(value: string) {
    this.filter.product = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public resetTable() {
    this.podDexList = new Entities(false, 400, "", new Pagination(1, 10), []);
  }

  @Mutation
  public setIsPaid(res: boolean) {
    this.isPaid = res;
  }

  @Mutation
  public setPagination(param: Pagination) {
    this.podDexList.pagination = param;
  }
}

export const PodDexController = getModule(PodDexStore);
