
import { Vue, Options } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import { PropType } from "@vue/runtime-core";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import {
  convertDecimalWithComma,
  formatPriceIDR,
  isMigrateFromElexys,
  addPrefixGenesis
} from "@/app/infrastructures/misc/Utils";
import { PodDexController } from "@/app/ui/controllers/PodDexController";
import { ReasonData } from "@/domain/entities/PodDex";
import {
  ReasonListDex,
  UpdatePodDexApiRequest
} from "@/data/payload/api/PodDexRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { RequestPriorityDeliveryController } from "@/app/ui/controllers/RequestPriorityDeliveryController";

@Options({
  emits: ["update:modelValue"],
  components: {
    ModalForm,
    UploadFile
  },
  props: {
    modelValue: {
      required: true
    },
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    }
  }
})
export default class Update extends Vue {
  get isMigrateElexys() {
    return isMigrateFromElexys();
  }

  get addPrefixGenesis() {
    return addPrefixGenesis();
  }

  mounted() {
    this.getReasonList();
  }
  formatPrice(value: number): string {
    return formatPriceIDR(value);
  }
  // Success
  isSuccess = false;
  setSuccess(value: boolean) {
    this.setConfirm(false);
    this.isSuccess = value;
  }
  isModalConfirmationRtsHal = false
  async getDetailSttNo() {
    try {
      MainAppController.showLoading();
      const resp = await RequestPriorityDeliveryController.getDetailRpd(
        this.detailData.sttNo
      );
      if (/RTS|HAL/.test(resp.rpdRequestType)) {
        this.isModalConfirmationRtsHal = true
      }
    } catch (error) {
      console.log(error, "error")
    } finally {
      MainAppController.closeLoading();
      MainAppController.closeErrorMessage();
    }
  }
  async onUpdate() {
    if (!this.isDisableConfirm) {
      const isSuccess = await PodDexController.updatePodDex(
        new UpdatePodDexApiRequest({
          attachment: this.form.file,
          sttNo: this.detailData.sttNo,
          isDelivered: this.form.sentValue === "yes",
          receiverName: this.form.receiverName,
          notDeliveredReason: this.reasonCode,
          remarks: this.isOtherReason ? this.form.remarks : ""
        })
      );
      this.setSuccess(isSuccess);
    }
  }
  // Confirm
  get isConfirm(): boolean {
    return PodDexController.isConfirm;
  }
  setConfirm(value: boolean) {
    PodDexController.setConfirm(value);
  }
  get isDisableConfirm(): boolean {
    if (this.isModalConfirmationRtsHal) {
      return false
    }
    return this.form.sentValue === "yes"
      ? !this.form.receiverName
      : !this.form.reasonValue;
  }
  props: any = this.$props;
  async onConfirm() {
    if (this.form.sentValue) {
      await this.getDetailSttNo()
      this.setConfirm(true);
      this.props.onClose();
    }
  }
  onUpdateRts() {
    this.isModalConfirmationRtsHal = false
    this.setConfirm(true);
    this.props.onClose();
  }
  onBack() {
    this.onReset();
    this.$emit("update:modelValue", true);
  }
  onBackRtsHal() {
    this.isModalConfirmationRtsHal = false
    this.onReset();
    this.$emit("update:modelValue", true);
  }
  onReset() {
    this.form.sentValue = "";
    this.form.receiverName = "";
    this.form.reasonValue = "";
    this.form.remarks = "";
    this.form.file = "";
    this.setConfirm(false);
  }
  onCloseUpdate() {
    this.onReset();
    this.setSuccess(false);
    PodDexController.setResetPodDexDetailData();
    PodDexController.fetchPodDexList();
  }
  // Sent
  get sentList() {
    return [
      {
        value: "",
        name: "Pilih"
      },
      {
        value: "yes",
        name: "Ya"
      },
      {
        value: "no",
        name: "Tidak"
      }
    ];
  }
  sentSelect = false;
  onOpenSentSelect(value: boolean) {
    this.sentSelect = value;
  }
  onSelectSentType(_: string, value: string) {
    this.form.sentValue = value;
    this.sentSelect = false;
  }
  form = {
    sentValue: "",
    reasonValue: "",
    remarks: "",
    receiverName: "",
    file: ""
  };
  // Reson No Confirm
  getReasonList() {
    PodDexController.getReasonList(
      new ReasonListDex({
        statusCode: "DEX",
        reasonStatus: "active"
      })
    );
  }
  get reasonList() {
    const res = [{ name: "Pilih alasan", value: "" }];
    PodDexController.reasonList.forEach((key: ReasonData) => {
      res.push({
        name: key.reasonDescription,
        value: key.reasonCode
      });
    });
    return res;
  }
  reasonSelect = false;
  onOpenReasonSelect(value: boolean) {
    this.reasonSelect = value;
  }
  reasonCode = "";
  onSelectReasonType(name: string, value: string) {
    this.form.reasonValue = name;
    this.reasonCode = value;
    this.reasonSelect = false;
  }
  get isOtherReason() {
    return this.form.reasonValue === "Kendala lainnya";
  }
  // Detail Data
  get detailData(): any {
    return PodDexController.podDexDetailData;
  }
  convertDecimalWithComma(value: number) {
    return convertDecimalWithComma(value, 2);
  }

  onlyAcceptedSomeChar(value: string) {
    const regex = /[^a-zA-Z0-9 ()'",.!?]/g;
    return value.replaceAll(regex, "");
  }
}
